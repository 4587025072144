'use client'
import React from 'react'
import Script from 'next/script'
import { usePathname } from 'next/navigation'

const SendinBlue = () => {
  const pathname = usePathname()
  return String(pathname).startsWith('/landing') ? null : (
    <Script id="sendinblue-script" strategy="lazyOnload">
      {`
      (function(d,w,c){
        w.SibConversationsID = '63f9157ca6e27e131d5c5424';
        w[c]=w[c]||function(){(w[c].q=w[c].q||[]).push(arguments);};
        var s=d.createElement('script');s.async=true;s.src='https://conversations-widget.sendinblue.com/sib-conversations.js';
        if(d.head)d.head.appendChild(s);
      })(document, window, 'SibConversations');
    `}
    </Script>
  )
}

export default SendinBlue
